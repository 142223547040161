export const getDefaultColDefs = () => {
  return {
    sortable: true,
    filter: "agTextColumnFilter",
    suppressHeaderMenuButton: true,
    filterParams: {
      maxNumConditions: 1,
      closeOnApply: true,
      filterOptions: ["contains"],
      buttons: ["apply"],
    },
    floatingFilter: true,
  };
};

export const getDefaultGridOptions = (columnDefs) => {
  return {
    rowModelType: "serverSide",
    columnDefs: columnDefs,
    cacheBlockSize: 100,
    blockLoadDebounceMillis: 500,
    autoSizeStrategy: {
      type: "fitGridWidth",
      defaultMinWidth: 100,
    },
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
    },
    getRowId: (params) => {
      return params.data.id;
    },
  };
};
